<template>
  <div>
    <t-row>
      <t-col cols="5">
        <b-form-group label="Modification" class="mt-0 d-inline" style="width:400px;">
          <b-button-group class="float-left">
            <b-button :variant="(setOrModify === 'modify')?'success':''" @click="setOrModify = 'modify'">Modify by</b-button>
            <b-button :variant="(setOrModify === 'set')?'success':''" @click="setOrModify = 'set'; dollarOrPercent = '$'">Set</b-button>
          </b-button-group>
          <b-form-select v-if="setOrModify === 'modify'" style="width:130px;" class="ml-3 float-left" v-model="increaseOrDecrease" :options="increaseOrDecreaseOptions"/>
          <b-input-group style="width:130px;" class="ml-3 float-left">
            <b-input-group-prepend>
              <b-button @click="toggleFixedPercent()">{{dollarOrPercent}}</b-button>
            </b-input-group-prepend>
            <b-form-input type="number" min="0.00" v-model="adjustAmount"/>
          </b-input-group>
        </b-form-group>
      </t-col>
      <t-col v-if="setOrModify === 'modify'">
        <b-form-group style="width:130px;" class="d-inline" :label="((increaseOrDecrease === 1) ? 'Upper limit' : 'Lower limit') + ' (optional)'">
          <b-form-input v-model="limit"/>
        </b-form-group>
      </t-col>
      <t-col>
        <b-form-group label="Match Type" v-if="tableType === 'keyword'" class="ml-3 float-left">
          <b-form-select v-model="matchType" :options="matchTypeOptions"/>
        </b-form-group>
      </t-col>
      <t-col cols="3">
        <b-form-group label="Result">
          <div class="d-inline-block">{{bidLanguage}}</div>
        </b-form-group>
      </t-col>
    </t-row>
  </div>
</template>

<script>

export default {
  name: 'bulkModifyBid',
  props: ['tableType'],
  data () {
    return {
      showModalVar: false,
      dollarOrPercent: '$',
      adjustAmount: 0,
      setOrModify: 'modify',
      matchType: 'all',
      matchTypeOptions: [
        { value: 'all', text: 'All' },
        { value: 'broad', text: 'Broad' },
        { value: 'phrase', text: 'Phrase' },
        { value: 'exact', text: 'Exact' }
      ],
      increaseOrDecrease: 1,
      increaseOrDecreaseOptions: [
        { text: 'Increase', value: 1 },
        { text: 'Decrease', value: -1 } /*,
        { text: 'Raise bids to first page CPC', value: 'firstPageCPC' },
        { text: 'Raise bids to top of page CPC', value: 'topOfPageCPC' }
        */
      ],
      limit: undefined // BE: lowerMin and upperMax
    }
  },
  watch: {
    setOrModify (newValue, oldValue) {
      this.emitChange()
    },
    dollarOrPercent () {
      this.emitChange()
    },
    matchType () {
      this.emitChange()
    },
    adjustAmount () {
      this.emitChange()
    },
    increaseOrDecrease () {
      this.emitChange()
    },
    limit () {
      this.emitChange()
    }
  },
  computed: {
    bidLanguage () {
      let matchTypeText = ''
      if (this.tableType === 'keyword') {
        if (this.matchType) {
          matchTypeText = `for ${this.matchType}`
        } else {
          return 'Select match type you want to change.'
        }
      }
      let modifyOrSetLanguage = ''
      if (this.setOrModify === 'modify') {
        modifyOrSetLanguage = (this.increaseOrDecrease === 1) ? 'Increase' : 'Decrease'
      } else {
        modifyOrSetLanguage = `${this.$filters.convertFromCamelCase(this.setOrModify)}`
      }
      return `${modifyOrSetLanguage} selected bids ${matchTypeText} ${(this.setOrModify === 'modify') ? 'by' : 'to'} ${(this.dollarOrPercent === '$') ? '$' : ''}${this.adjustAmount}${(this.dollarOrPercent === '%') ? '%' : ''}`
    }
  },
  methods: {
    emitChange () {
      let adjustType = 'FIXED'
      let adjustAmount = parseFloat(this.adjustAmount) * ((this.setOrModify === 'set') ? 1 : this.increaseOrDecrease) // increase or decrease based on what is selected but only if you are modifying

      if (this.setOrModify === 'set') {
        adjustType = 'SET'
        this.limit = 0
      } else if (this.dollarOrPercent === '%') {
        adjustType = 'PERCENT'
        adjustAmount = adjustAmount / 100
      }

      let configuration = { adjustType: adjustType, matchType: this.matchType, adjustAmount: adjustAmount }

      let limit = parseFloat(this.limit)
      if (this.limit && limit > 0) {
        configuration.limit = limit
      }

      this.$emit('change', configuration)
    },
    toggleFixedPercent () {
      if (this.setOrModify === 'modify') {
        this.dollarOrPercent = (this.dollarOrPercent === '$') ? '%' : '$'
      }
    }
  }
}
</script>

<style scoped>

</style>
