<template>
  <div>
    <b-form-group class="d-inline-block mr-3" label="Add or Remove Match Type" style="width:200px;">
      <b-form-select v-model="addOrRemove" :options="addOrRemoveOptions"/>
    </b-form-group>
    <b-form-group :label="`${(addOrRemove === 'enabledMatchTypes')?'Add':'Remove'} the following match types:`" class="d-inline-block mr-3">
      <b-form-checkbox-group :checked="matchType" @input="matchType = $event" :options="matchTypeOptions"></b-form-checkbox-group>
    </b-form-group>
    <b-form-group class="d-inline-block">
      <b-form-checkbox :checked="ignoreIfAllDisabled" @input="ignoreIfAllDisabled = $event">Remove keywords if all match types are taken away.</b-form-checkbox>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'bulkChangeMatchTypes',
  data () {
    return {
      addOrRemove: 'enabledMatchTypes',
      ignoreIfAllDisabled: true,
      matchType: [],
      addOrRemoveOptions: [
        { value: 'enabledMatchTypes', text: 'Add Match Type' },
        { value: 'disabledMatchTypes', text: 'Remove Match Type' }
      ],
      matchTypeOptions: [
        // { value: null, text: 'Select' },
        { value: 'broad', text: 'Broad' },
        { value: 'phrase', text: 'Phrase' },
        { value: 'exact', text: 'Exact' }
      ]
      // convertBroadMatchToBroadMatchModified: false,
      // convertBroadMatchModifiedToBroadMatch: false
    }
  },
  watch: {
    matchType (newValue, oldValue) {
      this.emitChange()
    },
    addOrRemove () {
      this.emitChange()
    },
    ignoreIfAllDisabled () {
      this.emitChange()
    }
    // convertBroadMatchModifiedToBroadMatch () {
    //   this.emitChange()
    // },
    // convertBroadMatchToBroadMatchModified () {
    //   this.emitChange()
    // }
  },
  methods: {
    emitChange () {
      const payload = {
        ignoreIfAllDisabled: this.ignoreIfAllDisabled
        // convertBroadMatchToBroadMatchModified: this.convertBroadMatchToBroadMatchModified,
        // convertBroadMatchModifiedToBroadMatch: this.convertBroadMatchModifiedToBroadMatch
      }
      payload[this.addOrRemove] = this.matchType
      this.$emit('change', payload)
    }
  }
}
</script>

<style scoped>

</style>
