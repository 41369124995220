<template>
  <div>
    <b-modal size="mostly-full"
             body-class="bulk-find-and-replace-keywords-modal"
             v-model="showModalVar"
             :title="`Modify Keywords in ${tableTypeCapital}s`"
             @ok="handleOk()"
             @hide="$emit('modal-closed')"
             ok-title="Apply"
    >
      <t-row>
        <t-col v-if="tableType !== 'adGroup'">
          <b-form-group :label="`Campaign Name Includes (any)`">
            <bubble-input v-model="campaignNamesInclude"/>
          </b-form-group>
        </t-col>
        <t-col>
          <b-form-group :label="`Ad Group Name Includes (any)`">
            <bubble-input v-model="adGroupNamesInclude"/>
          </b-form-group>
        </t-col>
        <t-col>
          <b-form-group :label="`Keyword Text Includes (any)`">
            <bubble-input v-model="keywordTextInclude"/>
          </b-form-group>
        </t-col>
        <t-col v-if="isBlueprintsEligible">
          <b-form-group label="Include Blueprints">
            <b-form-checkbox :checked="excludeBlueprints" @input="excludeBlueprints = $event">Include keywords attached to blueprints.</b-form-checkbox>
          </b-form-group>
        </t-col>
      </t-row>
      <t-row>
        <t-col>
          <b-card no-body>
            <b-tabs card lazy>
              <b-tab title="Replace Text" active @click="previewType = 'findAndReplace'">
                <t-row>
                  <t-col>
                    <b-form-group label="From Text">
                      <b-form-input v-model="fromText"/>
                    </b-form-group>
                  </t-col>
                  <t-col>
                    <b-form-group label="To Text">
                      <b-form-input v-model="toText"/>
                    </b-form-group>
                  </t-col>
                  <t-col>
                    <b-form-group label="Replace or Duplicate">
                      <b-form-select v-model="duplicate" :options="duplicateOptions"/>
                    </b-form-group>
                  </t-col>
                </t-row>
                <bulk-modify-keyword-table :fields="fields.findAndReplace" :items="previewData.keywordReplaceResults" :loading="loading" @preview="preview()"/>
                <span v-if="noResults" class="no-results">No Results</span>
              </b-tab>
              <b-tab title="Adjust Bids" @click="previewType = 'keywordBidAdjust'">
                <b-card-text>
                  <bulk-modify-bid table-type="keyword" @change="configurationData.keywordBidAdjust = $event"/>
                  <div class="text-muted">
                    Note: Only campaigns with manual CPC bidding are eligible
                  </div>
                  <bulk-modify-keyword-table :fields="fields.keywordBidAdjust" :items="previewData.keywordAdjusts" :loading="loading" @preview="preview()"/>
                  <span v-if="noResults" class="no-results">No Results</span>
                </b-card-text>
              </b-tab>
              <b-tab title="Change Match Types" @click="previewType = 'keywordMatchAdjust'">
                <b-card-text>
                  <bulk-change-match-types @change="configurationData.keywordMatchAdjust = $event"/>
                  <bulk-modify-keyword-table :fields="fields.keywordMatchAdjust" :items="previewData.keywordAdjusts" :loading="loading" @preview="preview()"/>
                  <span v-if="noResults" class="no-results">No Results</span>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </b-card>
        </t-col>
      </t-row>
      <template v-slot:modal-footer>
        <b-btn @click="showModalVar = false;$emit('modal-closed')">Cancel</b-btn>
        <b-btn @click="applyResults()" variant="success" :disabled="!(selectedResults.length > 0)"><fluency-icon v-if="saving" type="loop" class="rotating mr-1"></fluency-icon>Apply Results</b-btn>
      </template>
    </b-modal>
  </div>
</template>

<script>
import BulkModifyBid from './tableActions/bulkModifyBid'
import BulkModifyKeywordTable from './bulkModifyKeywordTable'
import BulkChangeMatchTypes from './tableActions/bulkChangeMatchTypes'
import BubbleInput from '../../common/forms/bubbleInput'

export default {
  name: 'bulkFindAndReplaceKeywordsModal',
  components: { BubbleInput, BulkChangeMatchTypes, BulkModifyKeywordTable, BulkModifyBid },
  props: ['showModal', 'tableType'],
  data () {
    return {
      showModalVar: false,
      fromText: '',
      toText: '',
      accountNameInclude: [],
      campaignNamesInclude: [],
      adGroupNamesInclude: [],
      keywordTextInclude: [],
      previewData: [],
      fields: {
        findAndReplace: [{ slot: 'selectRow', sticky: false }, 'accountName', 'campaignName', 'adGroupName', 'blueprint', { key: 'keywordText', label: 'Keyword', sortable: true, class: 'text-left', useDefaultTemplate: false }, { key: 'replacedText', label: 'Replace With', class: 'text-left', defaultWidth: 250 }],
        keywordBidAdjust: [{ slot: 'selectRow', sticky: false }, 'accountName', 'campaignName', 'adGroupName', { slot: 'keywordText', slotOption: 'static' }, 'broadMatchBid', 'phraseMatchBid', 'exactMatchBid', 'newBroadMatchBid', 'newPhraseMatchBid', 'newExactMatchBid'], // match type modification
        keywordMatchAdjust: [{ slot: 'selectRow', sticky: false }, 'accountName', 'campaignName', 'adGroupName', { slot: 'keywordText', slotOption: 'static' }, 'broadMatchBid', 'phraseMatchBid', 'exactMatchBid', 'newBroadMatchBid', 'newPhraseMatchBid', 'newExactMatchBid'] // match type modification
      },
      loading: false,
      saving: false,
      duplicate: false,
      duplicateOptions: [{ value: false, text: 'Replace' }, { value: true, text: 'Duplicate' }],
      excludeBlueprints: false,
      previewType: 'findAndReplace',
      configurationData: {},
      noResults: false
    }
  },
  computed: {
    isBlueprintsEligible () {
      return this.user.capabilities?.Blueprints
    },
    user () {
      return this.$store.getters.user
    },
    selected () {
      return this.$store.getters['table/selectedRowObjs'](this.tableType) || []
    },
    selectedResults () {
      return this.$store.getters['table/selectedRowObjs']('keyword-modify') || []
    },
    planIds () {
      if (this.tableType === 'account') {
        return this.selected.map(x => x.accountPlanId)
      } else if (this.tableType === 'campaign') {
        return this.selected.map(x => x.campaignPlanId)
      } else if (this.tableType === 'adGroup') {
        return this.selected.map(x => x.adGroupPlanId)
      } else if (this.tableType === 'keyword') {
        return this.selected.map(x => x.keywordPlanId)
      } else {
        return false
      }
    },
    tableTypeCapital () {
      return this.$filters.capitalize(this.tableType)
    },
    contentTemplateMap () {
      if (this.previewData.affectedTemplates) {
        return this.previewData.affectedTemplates.toMap(c => c.contentTemplateId)
      } else {
        return {}
      }
    }
  },
  watch: {
    showModal: {
      handler (newValue) {
        this.$setCompat(this, 'showModalVar', newValue)
        this.determineFields()
      },
      immediate: true
    },
    previewType: { // change tab
      handler () {
        this.previewData = []
        this.noResults = false
      }
    }
  },
  methods: {
    determineFields () {
      if (this.$route.query.id) { // something is selected besides just on main screen, don't need account column
        Object.keys(this.fields).forEach((fieldKey) => {
          // let indx = this.fields[fieldKey].indexOf('accountName')
          // this.fields[fieldKey].splice(indx, 1)
        })
      }
    },
    async preview () {
      this.loading = true
      this.noResults = false
      let payload = {}
      switch (this.previewType) {
        case 'findAndReplace':
          payload = {
            keywordReplace: {
              fromText: this.fromText,
              toText: this.toText
            },
            campaignNamesInclude: this.campaignNamesInclude,
            adGroupNamesInclude: this.adGroupNamesInclude,
            keywordTextInclude: this.keywordTextInclude
          }
          break
        case 'keywordBidAdjust':
          payload = {
            keywordBidAdjust: this.configurationData.keywordBidAdjust, // copies the keyword object emitted by bulkModifyBid
            campaignNamesInclude: this.campaignNamesInclude,
            adGroupNamesInclude: this.adGroupNamesInclude,
            keywordTextInclude: this.keywordTextInclude
          }
          break
        case 'keywordMatchAdjust':
          payload = {
            keywordMatchAdjust: this.configurationData.keywordMatchAdjust, // copies the keyword object emitted by bulkChangeMatchType
            campaignNamesInclude: this.campaignNamesInclude,
            adGroupNamesInclude: this.adGroupNamesInclude,
            keywordTextInclude: this.keywordTextInclude
          }
          break
        default:
      }
      payload[`${this.tableType}PlanIds`] = this.planIds
      let response = await this.$res.fetch.previewAndReplace(payload)
      if (response) {
        if ((response.keywordAdjusts && response.keywordAdjusts.length === 0) ||
          (response.keywordReplaceResults && response.keywordReplaceResults.length === 0)) {
          this.noResults = true
        }
        if (response.affectedTemplates) {
          let affectedTemplates = response.affectedTemplates.toMap(map => map.contentTemplateId)
          let keywordResults = []
          let responseKeywordAdjusts = response.keywordReplaceResults || response.keywordAdjusts
          responseKeywordAdjusts.forEach(keyword => {
            keywordResults.push({
              ...keyword,
              blueprint: (keyword.contentTemplateId) ? affectedTemplates[keyword.contentTemplateId].templateName : null
            })
          })
          if (response.keywordReplaceResults) {
            response.keywordReplaceResults = keywordResults
          } else if (response.keywordAdjusts) {
            response.keywordAdjusts = keywordResults
          }
        }
        this.$setCompat(this, 'previewData', response)
      }
      this.$setCompat(this, 'noResults', true)
      this.loading = false
    },
    async applyResults () {
      this.saving = true
      let keywordReplacementContainer = {}

      // Preserve original preview data before presentation modifications
      const selectedPlanIds = this.selectedResults.map(x => x.keywordPlanId)
      const selectedKeywordAdjustResults = this.previewData.keywordAdjusts?.filter(x => selectedPlanIds.includes(x.keywordPlanId))
      const selectedKeywordReplaceResults = this.previewData.keywordReplaceResults?.filter(x => selectedPlanIds.includes(x.keywordPlanId))
      selectedKeywordAdjustResults?.forEach(keyword => delete keyword.blueprint)
      selectedKeywordReplaceResults?.forEach(keyword => delete keyword.blueprint)

      switch (this.previewType) {
        case 'findAndReplace':
          keywordReplacementContainer = {
            duplicate: this.duplicate,
            excludeBlueprints: this.excludeBlueprints,
            keywordReplaceResults: selectedKeywordReplaceResults
          }
          break
        case 'keywordBidAdjust':
          keywordReplacementContainer = {
            keywordAdjusts: selectedKeywordAdjustResults
          }
          break
        case 'keywordMatchAdjust':
          keywordReplacementContainer = {
            keywordAdjusts: selectedKeywordAdjustResults
          }
          break
        default:
      }
      await this.$res.set.applyKeywordsByResults(keywordReplacementContainer)
      this.$toast('Keyword replacements applied', 'success')
      this.saving = false
      this.showModalVar = false
    }
  }
}
</script>

<style lang="scss" scoped>
.no-results {
  position:absolute;
  top:300px;
  left:calc(50% - 40px);
  display:block;
  font-size: 140%;
}
</style>
<style lang="scss">
.bulk-find-and-replace-keywords-modal {
  .keyword-table .table-scroll td {
    white-space: normal;
  }
  .keyword-table .table-scroll.table-keyword {
    overflow-y: scroll;
    overflow-x: scroll;
  }
  .keyword-table .new-table-sticky-header {
    max-height: 500px;
  }
  .accountName { width: 130px; }
  .table-fixed-width th.adGroupName { width: 130px; }
  .table-fixed-width th.campaignName { width:130px; }
  .table-fixed-width th.keywordText { width:160px; }
  .table-fixed-width .keywordTextBump { display: none !important; }
}
</style>
