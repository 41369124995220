<template>
  <div>
    <t-row>
      <t-col class="text-right">
        <b-btn @click="$emit('preview')" class="mb-4">Preview Results</b-btn>
      </t-col>
    </t-row>
    <t-row no-gutters style="height:calc(100vh - 530px);">
      <t-col class="keyword-table">
        <fluency-loader v-if="loading">Loading Keyword Replacement Preview</fluency-loader>
        <fluent-table
          v-if="(items && items.length > 0)"
          plan-type="keyword"
          primary-key="keywordPlanId"
          selected-row-override="keyword-modify"
          :fields="fields"
          :items="tableItems"
          ref="previewTable"
          :per-page="perPage"
          :current-page="currentPage"
          :use-flex-classes="true"
          :stickyHeader="false"
          :col-spans="colSpans">

          <template v-slot:cell(blueprint)="row">
            {{row.item.blueprint}}
          </template>

          <template v-slot:cell(replacedText)="row">
            <div class="d-flex justify-content-between align-items-center inline-edit">
              {{row.item.replacedText}}
              <popover-cancel-save class="pl-2"
                                   placement="left"
                                   :value="row.item.replacedText"
                                   @save="row.item.replacedText = $event">
                <template #edit="{value, update}">
                  <b-form-group label="Modify Text">
                    <b-form-input :value="value"
                                  @input="update"/>
                  </b-form-group>
                </template>
              </popover-cancel-save>
            </div>
          </template>

<!--          <template v-slot:cell(keywordTextReplacement)="row">-->
<!--            {{row.item.keywordText}}-->
<!--          </template>-->

        </fluent-table>
      </t-col>
    </t-row>
  </div>
</template>

<script>
import FluencyLoader from 'core-ui/components/common/fluencyLoader'
import PopoverCancelSave from '@/components/common/popoverCancelSave'
export default {
  name: 'bulkModifyKeywordTable',
  components: { PopoverCancelSave, FluencyLoader },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array
    },
    fields: {
      type: Array
    }
  },
  data () {
    return {
      perPage: 140,
      currentPage: 1,
      colSpans: {
        /*
        current: {
          width: 60,
          fields: ['broadMatchBid', 'phraseMatchBid', 'exactMatchBid']
        },
         */
        preview: {
          width: 60,
          fields: ['newIsEnhancedBroadMatch', 'newBroadMatchBid', 'newPhraseMatchBid', 'newExactMatchBid']
        }
      }
    }
  },
  computed: {
    // Requested for the field to say (auto) if the manage table says (auto). This would only apply to
    // keywords with MAXIMIZE_CONVERSIONS.
    tableItems () {
      return this.items.map(i => {
        if (i.biddingStrategy !== 'MAXIMIZE_CONVERSIONS') {
          return i
        }

        const adjusted = {...i}
        const bidFieldsToNum = ['broadMatchBid', 'exactMatchBid', 'phraseMatchBid', 'newBroadMatchBid']
        const bidFieldsToAuto = ['newExactMatchBid', 'newPhraseMatchBid']

        bidFieldsToNum.forEach(field => i[field] && (adjusted[field] = -1))
        bidFieldsToAuto.forEach(field => i[field] && (adjusted[field] = '(auto)'))

        return adjusted
      })
    }
  },
  methods: {
    clearSelected () {
      this.$refs.previewTable.clearSelected()
    }
  }
}
</script>

<style scoped>

</style>
